import React from "react";
import MissionVisionAll from "../MissionVision";
import PhilosphyAll from "../Philosphy";
import "./styles.scss"

const MissionVisionPhilosphy=()=>{
    return(
        <section className="vision-container">
            <MissionVisionAll/>
            <PhilosphyAll/>

        </section>
    )
}

export default MissionVisionPhilosphy;