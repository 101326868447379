import ConstructionImg1 from "../../assets/WhatsApp Image 2024-03-09 at 5.23.21 PM (1).jpeg"
import ConstructionImg2 from "../../assets/WhatsApp Image 2024-03-09 at 5.23.22 PM (1).jpeg"
import ConstructionImg3 from "../../assets/WhatsApp Image 2024-03-09 at 5.23.24 PM (1).jpeg"
import ConstructionImg4 from "../../assets/WhatsApp Image 2024-03-09 at 5.23.29 PM.jpeg"
import ConstructionImg5 from "../../assets/WhatsApp Image 2024-03-09 at 5.23.28 PM.jpeg"

export const videoAssets=[
    {
        id:"1",
        title:"Cnstruction Site",
        img:ConstructionImg1,
    },
    {
        id:"2",
        title:"Cnstruction Site",
        img:ConstructionImg2,
    },
    {
        id:"3",
        title:"Cnstruction Site",
        img:ConstructionImg3,
    },
    {
        id:"4",
        title:"Cnstruction Site",
        img:ConstructionImg4,
    },
    {
        id:"5",
        title:"Cnstruction Site",
        img:ConstructionImg5,
    },
]