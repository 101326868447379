import React from "react";
import { AllFacilities } from "../helperfunction";
import { Card, Col, Row } from "antd";
import "./styles.scss";

const Facilities = ({ heading }) => {
  return (
    <section className="facilities-container">
      {AllFacilities?.map((e) => {
       
          return (
            <>
             
              <Row gutter={[24,24]}>
                {e?.fac.map((f) => {
                  return (
                  
                      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        <Card className="facility-card">
                          
                          <img src={f?.img} alt="#" className="fac-img" />
                          <div className="facility-sub-title">{f?.facility}</div>
                        </Card>
                      </Col>
                   
                  );
                })}
              </Row>
            </>
          );
        }
      )}
    </section>
  );
};

export default Facilities;
