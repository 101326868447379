import {React,useEffect} from "react";
import { AllPossessionForms } from "../../HelperFunctions/homeBanner";
import { Card, Row, Col, Button } from "antd";
import possessForm from "./SampleApplication-of-Possession-of-Plot-Site-Plan.pdf"
import "./styles.scss";

const PossessionForms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="maps-all-container">
      <div className="title">Possession Forms Of All Towns</div>
      <Row gutter={[24, 24]}>
        {AllPossessionForms.map((e) => {
          return (
            <>
              <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                <Card className="town-map-card">
                  <iframe src={e?.form} title="Possession Form" className="town-map-img">

                  </iframe>
                  <div className="town-card-title">{e?.title}</div>
                 
                </Card>
              </Col>
            </>
          );
        })}
      </Row>
    </section>
  );
};

export default PossessionForms;
