import React from "react";
import "./styles.scss";

const CompanyIntro = () => {
  return (
    <section className="company-intro-section">
      <div className="company-img-container" />
      <section className="company-intro-text">
        <div className="small-text">Hussain Developers</div>
        <div className="title-text">At a Glance</div>
        <div className="p-text">
          <p>
            Hussain Developers has been shaping landscapes and lives in Pakistan
            since the company’s inception in 2010. Not just about building
            homes, Hussain Developers develops value-added, master-planned
            communities housing thousands of families who are enjoying a
            complete living experience. Upon completion, the under-development
            projects in Bahawalpur will accommodate more than a million
            residents. Hussain Developers 500 employees are delivering iconic
            developments, driving leadership, pioneering innovation and creating
            a legacy for generations to come.
          </p>
          <p>
            Hussain Developers aspires to be the greatest Pakistani real estate
            developer of all times, with projects offered to an eclectic mix of
            segments, at choice locations with world-class amenities, while
            ensuring the highest international standards, timely delivery and
            lifelong customer satisfaction.
          </p>
        </div>
      </section>
    </section>
  );
};

export default CompanyIntro;
