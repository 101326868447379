import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";

import "./styles.scss";

const { SubMenu } = Menu;

const MenuMedia = () => {
  const navigate = useNavigate();
  const handleNavigate = (link) => {
    navigate(link);
  };
  return (
    <Menu mode="vertical" className="downloads-menu">
      <SubMenu
        title={
          <div onClick={() => handleNavigate("/press-release")}>
            Press Release
          </div>
        }
      ></SubMenu>
      <SubMenu
        title={
          <div onClick={() => handleNavigate("/video-gallery")}>
            Video Gallery
          </div>
        }
      ></SubMenu>
    </Menu>
  );
};

export default MenuMedia;
