import React from "react";
import { MissionVision } from "../../HelperFunctions/homeBanner";
import "./styles.scss";

const MissionVisionAll = () => {
  return (
    <section className="mission-vision-section">
      {MissionVision.map((e) => {
        return (
          <div className="mission-vision-container">
            <img src={e?.image} alt="#" className="mission-img" />
            <div className="mission-title-text">
              <div className="mission-title">{e?.title}</div>
              <div className="mission-text">{e?.desc}</div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default MissionVisionAll;
