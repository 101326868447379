import React from "react";
import { Card, Col, Row } from "antd";
import { YoutubeFilled } from "@ant-design/icons";
import { videoAssets } from "./helperfunction";
import ytubeImg from "../../assets/Untitled.png"
import "./styles.scss";

const VideoCardsAll = () => {
  return (
    <section className="video-cards-all-container">
      <div className="component-title">Videos Gallery</div>
      <Row gutter={[24, 24]}>
        {videoAssets.map((e) => {
          return (
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Card className="video-card">
                <img src={e?.img} alt="#" className="video-img" />

                <a
                  href="https://www.youtube.com/watch?v=exjKz2MC6_E&t=22s"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={ytubeImg} alt="#" className="youtube-icon" />
                </a>

                <div className="video-title">{e?.title}</div>
              </Card>
            </Col>
          );
        })}
      </Row>
    </section>
  );
};

export default VideoCardsAll;
