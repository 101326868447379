import {React,useEffect} from "react";
import { useParams } from "react-router-dom";
import { PressReleases } from "../../../HelperFunctions/homeBanner";
import { Col, Row } from "antd";
import {
    CalendarFilled
  } from '@ant-design/icons';
  import "./styles.scss"

const PressDetail = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const params = useParams();
  const { pressId } = params;
  
  return (
    <section className="press-detail-container">
      {PressReleases?.map((e) => {
        if (e?.id === pressId) {
          return (
            <Row gutter={[24,24]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} >
                <div className="image-container">
                  <img src={e?.img} alt="#" className="detail-img" />
                </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <div className="press-detail-title">
                    {e?.title}
                </div>
                <div className="press-detail-date">
                <CalendarFilled /> {e?.date}
                </div>
                <p className="para-detail-text">
                    {e?.detail}
                </p>
              </Col>
            </Row>
          );
        }
      })}
    </section>
  );
};

export default PressDetail;
