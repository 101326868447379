import {React,useEffect} from "react";
import { useParams } from "react-router-dom";
import { Image, Carousel } from "antd";
import { AllProjects } from "../../../HelperFunctions/homeBanner";
import "./styles.scss";

const ProjectDetail = () => {
  
  const params = useParams();
  const { projectId } = params;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="town-map-container">
      {AllProjects?.map((e) => {
        if (e?.id === projectId) {
          return (
            <>
              <div className="town-title">{e?.title}</div>
              <div className="town-city">
                <img src={e?.icon} alt="" className="pin-icon-img" />
                <span className="town-city-text">
                  {e?.town},{e?.city}
                </span>
              </div>
              <div className="town-address">{e?.desc}</div>
              <div className="image-container">
                <Carousel
                  autoplay={true}
                  
                  className="home-banner-carousel"
                >
                  {e?.gallery.map((i) => {
                    return (
                      <div className="image-container">
                        <img src={i?.image} alt="#" className="town-img" />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
              <div className="detail-title">OverView</div>
              <p className="paragraph-text">{e?.overview}</p>
              <div className="detail-title">Location</div>
              <p className="paragraph-text">{e?.location}</p>
              <div className="detail-title">Payment Plan</div>
              <p className="paragraph-text">{e?.paymentplan}</p>  
              <p className="paragraph-text">{e?.paymentplanB}</p> 
              <p className="paragraph-text">{e?.paymentplanC}</p> 
              <p className="paragraph-text">{e?.paymntplanD}</p>          
              <div className="detail-title">Facilities & Amenities</div>
              <p  className="paragraph-text">
                There present several top-class facilities and amenities in the
                state-of-the-art Hussain Developers. One of the top reasons for
                becoming the public's top priority lies in its facilities and
                amenities. The properties of Hussain Developers are selling like
                hotcakes in the market due to its commitment to revamped
                community living. Have a look at some of its facilities and
                amenities.
              </p>
              {e?.facilities.map((f) => {
                return (
                  <ul>
                    <li className="paragraph-text">{f?.text}</li>
                  </ul>
                );
              })}
              <div className="detail-title">Construction Update</div>
              <p className="paragraph-text">{e?.construction}</p>
              <div className="detail-title">Project Map</div>
              <a href={e?.map} target="_blank" download={e?.map}>
                <img src={e?.map} alt="#" className="town-img" />
              </a>
            </>
          );
        }
      })}
    </section>
  );
};

export default ProjectDetail;
