import {React,useEffect} from "react";
import VideoCardsAll from "../../components/videoGallery";

const VideoSection =()=>{

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return <VideoCardsAll/>
}

export default VideoSection;