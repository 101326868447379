import React from "react";
import { Space } from "antd";
import CeImg from "../../assets/ceo.jpeg";
import FacebookImg from "../../assets/facebook.png";
import InstaImg from "../../assets/instagram.png";

import "./styles.scss";

const CeMessage = () => {
  return (
    <section className="ce-message-container">
      <section className="left-container">
        <div className="ce-heading-text">Message from CE</div>
        <p className="ce-paragraph">
          As the CEO of Hussain Developers, I am proud to lead a team committed
          to providing quality housing solutions across South Punjab. With 7
          projects in Bahawalpur, we understand the importance of a safe,
          secure, and comfortable home. Our mission is to offer modern,
          high-quality housing that caters to your needs and preferences. We
          prioritize excellence by continuously improving our products and
          services to meet your evolving needs. Our goal is to create functional
          and sustainable communities and building trust and loyalty with our
          customers. Thank you for choosing Hussain Developers as your preferred
          housing solution. We look forward to serving you for years to come.
        </p>
        {/* <p className="ce-paragraph">
          Bahria Town is a name that has been making news across oceans, on the
          cover of international papers & magazines for its construction marvels
          which is why we are the most preferred investment destination not only
          for Pakistanis but also in the eyes of expats as well as foreign
          investors. It is no surprise that we have won 10 accolades at the
          International Property Awards under various categories for our
          projects.
        </p>
        <p className="ce-paragraph">
          We have transformed real estate into a fine art, which is why at
          Bahria Town each project is treated like a sculpture which is molded
          according to each client’s individual requirements. Our goal is to
          bring Pakistan on the list of developed countries, which is why Bahria
          Town offers the strongest infrastructure in the country yet caters to
          all the different socioeconomic classes of Pakistan.
        </p> */}
        <div className="ce-name">Alamgir Mansha</div>
        <div className="ce-name">Chief Executive, Hussain Developers</div>
        <div className="socialmedia-logos-container">
          <Space direction="horizontal" size={20} align="center">
            <img src={FacebookImg} alt="" className="s-icon" />
            <img src={InstaImg} alt="" className="s-icon" />
          </Space>
        </div>
      </section>
      <section className="right-container">
        <div className="ce-image-container">
          <img src={CeImg} alt="#" className="ceo-img" />
        </div>
      </section>
    </section>
  );
};

export default CeMessage;
