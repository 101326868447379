import {React,useEffect} from "react";
import AboutUsAll from "../../components/AboutUsAll";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <AboutUsAll />;
};

export default AboutUs;
