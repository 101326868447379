import React from "react";
import NewsWeekImg from "../../assets/sevarage.jpeg";
import "./styles.scss";

const NewsWeekAll = () => {
  return (
    <section className="news-week-container">
      <section className="right-container">
        <div className="news-title">News Week</div>
        <div className="news-text">Sewarage work started</div>
      </section>
      <br/>
      <section className="left-container">
        <img src={NewsWeekImg} alt="#" className="news-week-img" />
      </section>
    </section>
  );
};

export default NewsWeekAll;
