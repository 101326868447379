import React from "react";
import { Row, Col, Space } from "antd";
import {
  UsergroupAddOutlined,
  UserOutlined,
  TrophyOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import "./styles.scss";
const KeyFeaturesAll = () => {
  return (
    <section className="key-container">
      <div className="key-title">Our Story in 4 Key Figures</div>
      <Row gutter={[24,24]} className="social-container">
        <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <Space direction="vertical" align="center">
            <UsergroupAddOutlined className="k-icon" />
            <div className="feature-title">Thousands of</div>
            <div className="feature-text">Employees</div>
          </Space>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Space direction="vertical" align="center">
            <UserOutlined className="k-icon" />
            <div className="feature-title">250,000+</div>
            <div className="feature-text">Customers</div>
          </Space>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Space direction="vertical" align="center">
            <TrophyOutlined className="k-icon" />
            <div className="feature-title">23</div>
            <div className="feature-text">Years of Experience</div>
          </Space>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
          <Space direction="vertical" align="center">
            <CheckOutlined className="k-icon" />
            <div className="feature-title">100+</div>
            <div className="feature-text">Projects Completed</div>
          </Space>
        </Col>
      </Row>
    </section>
  );
};

export default KeyFeaturesAll;
