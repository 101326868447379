import React from "react";
import PressReleaseCard from "./PressCard";
import "./styles.scss";

const MediaCenter = () => {
  return (
    <section className="media-center-container">
      <div className="title">Press Release</div>
      <PressReleaseCard/>
    </section>
  );
};

export default MediaCenter;
