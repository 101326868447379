import React from "react";
import { Divider } from "antd";
import LogoImg from "../../assets/logo.jpeg";
import "./styles.scss";

const ComponentDivider = () => {
  return (
    <section className="divider-section">
      <Divider>
        <img src={LogoImg} alt="#" className="logo-divider" />
      </Divider>
    </section>
  );
};

export default ComponentDivider;
