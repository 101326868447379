import educationImg1 from "../../assets/heigh school 2.jpg";
import educationImg2 from "../../assets/2ND TRIANGLE PARK WITH BORDER.png";
//
import healthCareImg1 from "../../assets/WhatsApp Image 2022-06-28 at 5.32.02 PM.jpeg";
import healthCareImg2 from "../../assets/HOSPITAL 2.jpg";
import healthCareImg3 from "../../assets/proposal 2.jpg";
import healthCareImg4 from "../../assets/WhatsApp Image 2022-06-28 at 5.32.03 PM.jpeg";
import healthCareImg5 from "../../assets/cash & carry 2.jpg";
//
import infrastructureImg1 from "../../assets/VIEW 6.jpg";
import infrastructureImg2 from "../../assets/view 7.jpg";
import infrastructureImg3 from "../../assets/triangle sports park 2.jpg";
import infrastructureImg4 from "../../assets/triangle sports park..jpg";
import infrastructureImg5 from "../../assets/triangle sports park.jpg";
//


export const AllFacilities = [
    {
        id: "1",
        town: "1st",
        title: "Education",
        fac: [
            {
                facility: "High School",
                img: educationImg1,
            },
            {
                facility: "Family Park",
                img: educationImg2,
            },
            {
                facility: "Commercial Area",
                img: healthCareImg1,
            },
            {
                facility: "Hospital",
                img: healthCareImg2,
            },
            {
                facility: "Community Center",
                img: healthCareImg3,
            },
            {
                facility: "Plaza",
                img: healthCareImg4,
            },
            {
                facility: "Cash & Carry",
                img: healthCareImg5,
            },
            {
                facility: "Green Belts",
                img: infrastructureImg1,
            },
            {
                facility: "Wide Roads",
                img: infrastructureImg2,
            },
            {
                facility: "Clean Atmosphere",
                img: infrastructureImg3,
            },
            {
                facility: "Good Infrastructure",
                img: infrastructureImg4,
            },
            {
                facility: "Recreational Park",
                img: infrastructureImg5,
            },
        ]
    },
  
]