import React from "react";
import FounderMessage from "../FounderMessage";
import ComponentDivider from "../../common/Divider";
import CeMessage from "../CeMessage";
import CompanyIntro from "../CompanyInfo";
import MissionVisionPhilosphy from "../MissionVisionPhilosphy";
import AwardsAll from "../Awards";
import NewsWeekAll from "../NewsWeek";
import ExclusivityAll from "../Exclusivity";
import KeyFeaturesAll from "../KeyFigures";

const AboutUsAll = () => {
 
  return (
    <section className="about-us-all-container">
      <FounderMessage />
      <ComponentDivider />
      <CeMessage />
      <CompanyIntro />
      <MissionVisionPhilosphy />
      <ComponentDivider />
      <AwardsAll />
      <ComponentDivider />
      <NewsWeekAll />
      <ComponentDivider />
      <ExclusivityAll />
      <ComponentDivider />
      <KeyFeaturesAll />
    </section>
  );
};

export default AboutUsAll;
