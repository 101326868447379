import {React,useEffect} from "react";
import Projects from "../../components/ProjectAll";

const ProjectsAll = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return <Projects />;
};

export default ProjectsAll;
