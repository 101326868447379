import React from "react";
import { HomeBanners } from "../../HelperFunctions/homeBanner";
import { Carousel } from "antd";

import "./styles.scss";
const HomeBannersAll = () => {
  return (
    <Carousel autoplay={true} dots={false} className="home-banner-carousel">
      {HomeBanners?.map((e) => {
        return (
          <div className="home-banner-container">
            <img src={e?.image} alt="#" className="home-banner-img" />
          </div>
        );
      })}
    </Carousel>
  );
};

export default HomeBannersAll;
