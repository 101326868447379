import {React,useEffect} from "react";
import MediaCenter from "../../components/MediaCenter";

const MediaCenterAll=()=>{
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return <MediaCenter/>
}

export default MediaCenterAll;