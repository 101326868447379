import {React,useEffect}  from "react";
import AllFacilities from "../../components/Facilities";

const FacilitiesAll=()=>{

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return <AllFacilities/>

    
}

export default FacilitiesAll;