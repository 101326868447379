import React from "react";
import { useNavigate } from "react-router-dom";
import { Space, FloatButton } from "antd";
import { PhoneFilled, MailFilled, WhatsAppOutlined } from "@ant-design/icons";
import PinIcon from "../../assets/map-icon.jpg";
import WhatsAppIcon from "../../assets/whatspp.avif";
import FooterLower from "../FooterLower";
import "./styles.scss";

const FooterAll = () => {
  const navigate = useNavigate();

  const handleNavItemClick = (navItem) => {
    navigate(`/${navItem}`);
  };

  const handleWhatsApp = () => {
    const phoneNumber = "+923007882328";
    const whatsappURL = `https://wa.me/${phoneNumber}`;
    window.open(whatsappURL);
  };

  return (
    <>
      <section className="footer-section">
        <section className="branches">
          <div className="title">Corporate Offices</div>
          <div className="contact-text">
            <Space align="flex-start">
              <img src={PinIcon} alt="#" className="pin-icon-img" />
              Husssain developers & Builders Jhangi Wala Road Oppt Sir Sadiq
              Hospital
            </Space>
          </div>
          {/* <div className="contact-text">
            <Space align="flex-start">
              <img src={PinIcon} alt="#" className="pin-icon-img" />
              Bahawal Pur
            </Space>
          </div>
          <div className="contact-text">
            <Space align="flex-start">
              <img src={PinIcon} alt="#" className="pin-icon-img" />
              Bahawal Pur
            </Space>
          </div>
          <div className="contact-text">
            <Space align="flex-start">
              <img src={PinIcon} alt="#" className="pin-icon-img" />
              Bahawal Pur
            </Space>
          </div>
          <div className="contact-text">
            <Space align="flex-start">
              <img src={PinIcon} alt="#" className="pin-icon-img" />
              Bahawal Pur
            </Space>
          </div> */}
        </section>
        <section className="links">
          <div className="title">Quick Links</div>
          <div className="contact-text" onClick={() => handleNavItemClick("")}>
            About
          </div>
          <div
            className="contact-text"
            onClick={() => handleNavItemClick("facilities")}
          >
            Facilities
          </div>
          <div
            className="contact-text"
            onClick={() => handleNavItemClick("projects")}
          >
            Projects
          </div>
          <div
            className="contact-text"
            onClick={() => handleNavItemClick("downloads/maps")}
          >
            Maps
          </div>
          <div
            className="contact-text"
            onClick={() => handleNavItemClick("possession-forms")}
          >
            Possession Forms
          </div>
        </section>
        <section className="contacts">
          <div className="title">24 / 7 Toll Free</div>
          <div className="contact-text">
            <PhoneFilled /> 0304-1111490
          </div>
          <div className="contact-text">
            <MailFilled /> Hussaindevelopers.builders@gmail.com
          </div>
        </section>
      </section>
      <FloatButton
        icon={<WhatsAppOutlined />}
        className="whats-app-button"
        onClick={() => handleWhatsApp()}
      />
      <FooterLower />
    </>
  );
};

export default FooterAll;
