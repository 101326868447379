import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";

import "./styles.scss";

const { SubMenu } = Menu;

const MenuFacilities = () => {
  const navigate = useNavigate();
  return (
    <Menu
      mode="vertical"
      //   style={{ maxHeight: "500px", overflow: "auto" }}
      className="facilities-menu"
      // onOpenChange={(key) => setSelectedKey(key)}
      // selectedKeys={[selectedKey]}
    >
      <SubMenu title={<div onClick={()=>navigate("/facilities")}>Al Rahim Town</div>}></SubMenu>
      <SubMenu title={<div onClick={()=>navigate("/facilities")}>Al Rahim Villas</div>}></SubMenu>
      <SubMenu title={<div onClick={()=>navigate("/facilities")}>Al Rahim City</div>}></SubMenu>
      <SubMenu title={<div onClick={()=>navigate("/facilities")}>Al Rahim Paradise</div>}></SubMenu>
      <SubMenu title={<div onClick={()=>navigate("/facilities")}>Al Rahim Housing Scheme</div>}></SubMenu>
      <SubMenu title={<div onClick={()=>navigate("/facilities")}>Al Rahim Homes Housing Scheme</div>}></SubMenu>
      <SubMenu title={<div onClick={()=>navigate("/facilities")}>Al Rahim Smart City</div>}></SubMenu>
      <SubMenu title={<div onClick={()=>navigate("/facilities")}>Al Rahim Commercial Market</div>}></SubMenu>
    </Menu>
  );
};

export default MenuFacilities;
