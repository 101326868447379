import React, { useState } from "react";
import { Drawer, Typography } from "antd";
import {
  CopyrightOutlined,
  MenuOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AgencyTitleImg from "../../assets/logo.jpeg";
import MenuLine from "../../assets/Group 2994.png";

import "./styles.scss";

const DrawerMenu = () => {
  const { Text } = Typography;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [mediaOpen, setMediaOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [facilitiesOpen, setFacilitiesOpen] = useState(false);

  const handleClick = (navItem) => {
    navigate(`/${navItem}`);
    setOpen(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleMouseEnter = () => {
    setMediaOpen(false);
    setDownloadOpen(false);
    setFacilitiesOpen(false);
  };

  return (
    <>
      {/* <Button type="primary" onClick={showDrawer} className="collapse-button">
        <MenuOutlined />
      </Button> */}
      <MenuOutlined onClick={showDrawer} className="menu-line" />
      {/* <i className="ri-menu-fill" onClick={showDrawer}>
        <img className="close-outlined" src={MenuLine} alt="" />
      </i> */}
      <Drawer placement="top" onClose={onClose} open={open} className="drawer">
        <div className="drawer-items">
          <section
            className="mobile-logo-section"
            onClick={() => handleClick("")}
            onMouseEnter={() => handleMouseEnter()}
          >
            <img className="mobile-logo-text" src={AgencyTitleImg} alt="" />
          </section>

          <div>
            {/* <div className="list-items" onClick={() => handleClick("")}>
              <img src={HomeIcon} alt="#" />
              <Text className="text">Home</Text>
              <img src={ArrowIcon} alt="#" className="arrow-icon" />
            </div> */}
            <div
              className="list-items"
              onClick={() => handleClick("")}
              onMouseEnter={() => handleMouseEnter()}
            >
              {/* <img src={ABoutIcon} alt="#" /> */}
              <Text className="text">About Us</Text>
              {/* <img src={ArrowIcon} alt="#" className="arrow-icon" /> */}
            </div>
            <div
              className="list-items"
              onClick={() => handleClick("projects")}
              onMouseEnter={() => handleMouseEnter()}
            >
              {/* <img src={ProjectIcon} alt="#" /> */}
              <Text className="text">Projects</Text>
              {/* <img src={ArrowIcon} alt="#" className="arrow-icon" /> */}
            </div>
            <div
              className="list-items"
              onClick={() => setMediaOpen(true)}
              onMouseEnter={() => setMediaOpen(true)}
            >
              {/* <img src={ServicesIcon} alt="#" /> */}
              <Text className="text">
                Media Center{" "}
                <span>
                  {" "}
                  <CaretDownOutlined />
                </span>
              </Text>
            </div>
            {mediaOpen && (
              <div
                className="sub-menu-media"
                onMouseLeave={() => setMediaOpen(false)}
              >
                <div
                  className="sub-menu-items"
                  onClick={() => handleClick("press-release")}
                >
                  Press Release
                </div>
                <div
                  className="sub-menu-items"
                  onClick={() => handleClick("video-gallery")}
                >
                  Video Gallery
                </div>
              </div>
            )}

            <div
              className="list-items"
              onClick={() => setDownloadOpen(true)}
              onMouseEnter={() => setDownloadOpen(true)}
            >
              {/* <img src={BlogIcon} alt="#" /> */}
              <Text className="text">
                Downloads{" "}
                <span>
                  {" "}
                  <CaretDownOutlined />
                </span>
              </Text>

              {/* <img src={ArrowIcon} alt="#" className="arrow-icon" /> */}
            </div>
            {downloadOpen && (
              <div
                className="sub-menu-media"
                onMouseLeave={() => setDownloadOpen(false)}
              >
                <div
                  className="sub-menu-items"
                  onClick={() => handleClick("downloads/maps")}
                >
                  Maps
                </div>
                <div
                  className="sub-menu-items"
                  onClick={() => handleClick("possession-forms")}
                >
                  Possession Forms
                </div>
                <div
                  className="sub-menu-items"
                  onClick={() => handleClick("sample-forms")}
                >
                  Samples
                </div>
              </div>
            )}
            <div
              className="list-items"
              onClick={() => setFacilitiesOpen(true)}
              onMouseEnter={() => setFacilitiesOpen(true)}
            >
              {/* <img src={FaqIcon} alt="#" /> */}
              <Text className="text">
                Facilities{" "}
                <span>
                  {" "}
                  <CaretDownOutlined />
                </span>
              </Text>
              {/* <img src={ArrowIcon} alt="#" className="arrow-icon" /> */}
            </div>
            {facilitiesOpen && (
              <div
                className="sub-menu-media"
                onMouseLeave={() => setFacilitiesOpen(false)}
              >
                <div
                  className="sub-menu-items"
                  onClick={() => handleClick("facilities")}
                >
                  Al Rahim Town
                </div>
                <div
                  className="sub-menu-items"
                  onClick={() => handleClick("facilities")}
                >
                  Al Rahim Villas
                </div>
                <div
                  className="sub-menu-items"
                  onClick={() => handleClick("facilities")}
                >
                  Al Rahim City
                </div>
                <div
                  className="sub-menu-items"
                  onClick={() => handleClick("facilities")}
                >
                  Al Rahim Paradise
                </div>
                <div
                  className="sub-menu-items"
                  onClick={() => handleClick("facilities")}
                >
                  Al Rahim Housing Scheme
                </div>
                <div
                  className="sub-menu-items"
                  onClick={() => handleClick("facilities")}
                >
                  Al Rahim Homes Housing Scheme
                </div>
                <div
                  className="sub-menu-items"
                  onClick={() => handleClick("facilities")}
                >
                  Al Rahim Smart City
                </div>
                <div
                  className="sub-menu-items"
                  onClick={() => handleClick("facilities")}
                >
                  Al Rahim Commercial Market
                </div>
              </div>
            )}
            <div
              className="list-items"
              onClick={() => handleClick("contacts")}
              onMouseEnter={() => handleMouseEnter()}
            >
              {/* <img src={PhoneIcon} alt="#" /> */}
              <Text className="text">Contact Us</Text>
              {/* <img src={ArrowIcon} alt="#" className="arrow-icon" /> */}
            </div>
          </div>
          <section
            className="all-rights-reserved-mobile"
            onMouseEnter={() => handleMouseEnter()}
          >
            <CopyrightOutlined className="copy-right" />
            <div className="copy-right-text">
              2019 Techtsy. All Rights Reserved
            </div>
          </section>
        </div>
      </Drawer>
    </>
  );
};
export default DrawerMenu;
