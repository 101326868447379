import React from "react";
import { useNavigate } from "react-router-dom";
import { AllProjects } from "../../HelperFunctions/homeBanner";
import { Card, Row, Col, Button } from "antd";
import "./styles.scss";

const Projects = () => {
  const navigate = useNavigate();
  return (
    <section className="maps-all-container">
      <div className="title">All Projects</div>
      <Row gutter={[32, 32]}>
        {AllProjects.map((e) => {
          return (
            <>
              <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                <Card className="town-map-card">
                  <div className="image-container">
                    <img src={e?.img} alt="#" className="town-map-img" />
                  </div>
                  <div className="town-card-title">{e?.title}</div>
                  <Button
                    type="primary"
                    block
                    onClick={() => navigate(`/projects/${e?.id}`)}
                  >
                    View Detail
                  </Button>
                </Card>
              </Col>
            </>
          );
        })}
      </Row>
    </section>
  );
};

export default Projects;
