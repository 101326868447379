import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Row, Col } from "antd";
import { PressReleases } from "../../../HelperFunctions/homeBanner";

import "./styles.scss"

const PressReleaseCard = () => {
    const navigate = useNavigate();
  return (
    <>
      <Row gutter={[24,24]}>
        {PressReleases.map((e) => {
          return (
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Card className="press-release-card">
                <div className="image-container">
                  <img src={e?.img} alt="#" className="town-img" />
                </div>

                <div className="press-title" onClick={()=>navigate(`/press-release/${e?.id}`)}>{e?.title}</div>
                <div className="press-date">{e?.date}</div>
                <div className="paragraph-card-text">{e?.desc}</div>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default PressReleaseCard;
