import { React, useEffect } from "react";
import { Row, Col, Input, Space, Select, Divider } from "antd";
import { MailFilled } from "@ant-design/icons";
import { LocalOffices } from "../../HelperFunctions/homeBanner";
import ContactBannerImg from "../../assets/Contact_Header3.jpg";
import FacebookImg from "../../assets/facebook.png";
import TwitterImg from "../../assets/twitter.png";
import InstaImg from "../../assets/instagram.png";
import "./styles.scss";

const ContatcUs = () => {
  const { Option } = Select;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { TextArea } = Input;

  return (
    <section className="contact-us-container">
      <section className="banner-section">
        <img src={ContactBannerImg} alt="#" className="about-banner-img" />
        {/* <div className="about-us">Contact Us</div> */}
      </section>
      <Row className="contact-contents-section" gutter={[24, 24]}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className="left-container"
        >
          <div className="project-heading">What Can We Help You With ?</div>
          <br />
          {/* <div className="socialmedia-logos-container">
            <Space direction="horizontal" size={20} align="center">
              <img src={FacebookImg} alt="" className="s-icon" />
              <img src={TwitterImg} alt="" className="s-icon" />
              <img src={InstaImg} alt="" className="s-icon" />
              <img src={PinterestImg} alt="" className="s-pin-icon" />
            </Space>
          </div> */}
          <select
            defaultValue="none"
            placeholder="select"
            className="select-container"
          >
            <option>Sale</option>
            <option>Purchase</option>
          </select>
          <div className="project-heading">Contact Details</div>
          <Input
            placeholder="Name"
            block
            size="large"
            className="comment-input"
          />
          <Row gutter={[24, 24]} className="central-row">
            <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
              <Input
                block
                size="large"
                className="comment-input"
                placeholder="Email Address"
              />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Input
                block
                size="large"
                className="comment-input"
                placeholder="Phone Number"
              />
            </Col>
          </Row>
          <div className="project-heading">Message</div>
          <TextArea
            size="large"
            placeholder="Comment*"
            block
            className="comment-input"
          />
          <div className="active-tab-button">Send Message</div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <section className="add-comment-container">
            <div className="call-us">Call Us at (24/7) Toll Free</div>
            <div className="call-us">0304-1111490</div>
            {/* <div className="address-text">
              If calling from outside the Pakistan, please contact us on below
            </div>
            <div className="address-text">Dubai</div>
            <div className="address-text">BTMS Consultancy DMCC</div>
            <div className="address-text">Advertising & Marketing Services</div>
            <div className="address-text">4002, 40th Floor</div>
            <div className="address-text">
              HDS Business Center, Cluster M, Jumeriah Lake Towers
            </div>
            <div className="address-text">PO Box No. 191785</div>
            <div className="address-text">Dubai, UAE</div> */}
            <Divider />
            <div className="call-us">You can also email us @</div>
            <section className="inquiry-section">
              <div className="sales-inquiry">
                <MailFilled className="mail-icon" />
                <div className="sales-text">Sales Inquiries</div>
                <div className="email-address">Hussaindevelopers.builders@gmail.com</div>
              </div>
              <div className="sales-inquiry">
                <MailFilled className="mail-icon" />
                <div className="sales-text">Existing Customers</div>
                <div className="email-address">Hussaindevelopers.builders@gmail.com</div>
              </div>
            </section>
            <div className="call-us">Follow Us</div>
            <div className="socialmedia-logos-container">
              <Space direction="horizontal" size={20} align="center">
                <img src={FacebookImg} alt="" className="s-icon" />
                <img src={TwitterImg} alt="" className="s-icon" />
                <img src={InstaImg} alt="" className="s-icon" />
                <img src={FacebookImg} alt="" className="s-icon" />
                <img src={TwitterImg} alt="" className="s-icon" />
                <img src={InstaImg} alt="" className="s-icon" />
              </Space>
            </div>
            <Divider />
          </section>
          <section className="local-offices">
            <div className="call-us">Head Office</div>
            {LocalOffices?.map((e) => {
              return (
                <>
                  <div className="office-container">
                    <div className="city-name">{e?.city}</div>
                    <div className="city-address">
                      <p>{e?.address}</p>
                      <strong>P.</strong>
                      &nbsp;
                      <span className="number-phone">{e?.mobile}</span>
                      <br />
                      <strong>P.</strong> &nbsp;
                      <span className="number-phone">{e?.phone}</span>
                    </div>
                  </div>
                  <Divider />
                </>
              );
            })}
          </section>
          {/* <section className="local-offices">
            <div className="call-us">International Offices</div>
            {LocalOffices.map((e) => {
              return (
                <>
                  <div className="office-container">
                    <div className="city-name">{e?.city}</div>
                    <div className="city-address">
                      <p>{e?.address}</p>
                      <strong>P.</strong>
                      &nbsp;
                      <span className="number-phone">{e?.mobile}</span>
                      <br />
                      <strong>P.</strong> &nbsp;
                      <span className="number-phone">{e?.phone}</span>
                    </div>
                  </div>
                  <Divider />
                </>
              );
            })}
          </section> */}
        </Col>
      </Row>
    </section>
  );
};

export default ContatcUs;
