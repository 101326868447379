import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CaretDownOutlined } from "@ant-design/icons";
import LogoImg from "../../assets/logo.jpeg";
import HomeBannersAll from "../HomeBanner";
import DrawerMenu from "../drawer";
import "./styles.scss";
import { Space } from "antd";
import MenuDownloads from "../DownloadsMenu";
import MenuFacilities from "../FacilitiesMenu";
import MenuMedia from "../MediaCenterMenu";

const HeaderMain = () => {
  const navigate = useNavigate();
  const [activeNavItem, setActiveNavItem] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [facilityOpen, setFacilityOpen] = useState(false);
  const [menuMediaOpen, setMenuMediaOpen] = useState(false);

  const handleNavItemClick = (navItem) => {
    setActiveNavItem(navItem);
    navigate(`/${navItem}`)
  };

  const handleMouseEnter=()=>{
    setFacilityOpen(false)
    setMenuOpen(false)
    setMenuMediaOpen(false)
  }

  const handleEnterMouse=()=>{
    setMenuOpen(true)
    setFacilityOpen(false)
    setMenuMediaOpen(false)
  }

  const handleFacilityOpen=()=>{
    setMenuOpen(false)
    setFacilityOpen(true)
    setMenuMediaOpen(false)
  }

  const handleMediaMenu=()=>{
    setMenuOpen(false)
    setFacilityOpen(false)
    setMenuMediaOpen(true)
  }

  return (
    <>
      <section className="navbar-container">
        <section className="logo-container" onClick={()=>navigate("/")}>
          <img src={LogoImg} alt="#" className="logo-img" />
        </section>
        <section className="navbar-items">
          <div
          onMouseEnter={() => handleMouseEnter()}
            style={{
              borderBottom:
                activeNavItem === "" ? "2px solid goldenrod" : "none",
              color: activeNavItem === "" ? "goldenrod" : "white",
            }}
            className="nav-items"
            onClick={() => handleNavItemClick("")}
          >
            About
          </div>

          <div
            className="nav-items"
            onMouseEnter={()=>handleMouseEnter()}
            style={{
              borderBottom:
                activeNavItem === "projects" ? "2px solid goldenrod" : "none",
              color: activeNavItem === "projects" ? "goldenrod" : "white",
            }}
            onClick={() => handleNavItemClick("projects")}
          >
            Projects
          </div>
          <div
            className="nav-items"
            onMouseEnter={() => handleMediaMenu()}
            style={{
              borderBottom:
                activeNavItem === "media-center"
                  ? "2px solid goldenrod"
                  : "none",
              color: activeNavItem === "media-center" ? "goldenrod" : "white",
            }}
            onClick={() => setActiveNavItem("media-center")}
          >
            Media Center
            <span>
              <CaretDownOutlined />
            </span>
            {menuMediaOpen === true && (
              <div
                className="menu-section"
                onMouseLeave={() => setMenuMediaOpen(false)}
              >
                <MenuMedia />
              </div>
            )}
          </div>

          <div
            className="nav-items"
            onMouseEnter={() => handleEnterMouse()}
            style={{
              borderBottom:
                activeNavItem === "downloads" ? "2px solid goldenrod" : "none",
              color: activeNavItem === "downloads" ? "goldenrod" : "white",
            }}
            onClick={() => setActiveNavItem("downloads")}
          >
            Downloads
            <span>
              <CaretDownOutlined />
            </span>
            {menuOpen === true && (
              <div
                className="menu-section"
                onMouseLeave={() => setMenuOpen(false)}
              >
                <MenuDownloads />
              </div>
            )}
          </div>

          <div
            className="nav-items"
            onMouseEnter={() => handleFacilityOpen()}
            style={{
              borderBottom:
                activeNavItem === "facilities" ? "2px solid goldenrod" : "none",
              color: activeNavItem === "facilities" ? "goldenrod" : "white",
            }}
            onClick={() => setActiveNavItem("facilities")}
          >
            Facilities
            <span>
              <CaretDownOutlined />
            </span>
            {facilityOpen === true && (
              <div onMouseLeave={() => setFacilityOpen(false)}>
                <MenuFacilities />
              </div>
            )}
          </div>

          <div
            className="nav-items"
            onMouseEnter={() => handleMouseEnter()}
            style={{
              borderBottom:
                activeNavItem === "contacts" ? "2px solid goldenrod" : "none",
              color: activeNavItem === "contacts" ? "goldenrod" : "white",
            }}
            onClick={() => handleNavItemClick("contacts")}
          >
            Contacts
          </div>
        </section>
        <DrawerMenu />
      </section>

      <HomeBannersAll />
    </>
  );
};

export default HeaderMain;
