import React from "react";
import { AwardList } from "../../HelperFunctions/homeBanner";
import { Divider } from "antd";
import AwardImg from "../../assets/awards.jpg";
import "./styles.scss"

const AwardsAll = () => {
  return (
    <section className="awards-container">
      <section className="left-container">
        <div className="awards-title">
          Hussain Developer WON 10 Prestigious Awards
        </div>
        <div className="awards-list">
          {AwardList.map((e) => {
            return (
              <>
                <div className="award-list-text">{e?.desc}</div>
                <Divider />
              </>
            );
          })}
        </div>
      </section>
      <section className="right-container">
        <img src={AwardImg} alt="#" className="award-img"/>
      </section>
    </section>
  );
};

export default AwardsAll;
