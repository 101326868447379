import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";

import "./styles.scss";

const { SubMenu } = Menu;

const MenuDownloads = () => {
  const navigate = useNavigate();
  
  return (
    <Menu mode="vertical" className="downloads-menu">
      <SubMenu title={<div onClick={()=>navigate("/downloads/maps")}>Maps</div>}>
        {/* <Menu.Item className="sub-menu-item" onClick={() => handleNavigate(1)}>
          First Town
        </Menu.Item>
        <Menu.Item className="sub-menu-item" onClick={() => handleNavigate(2)}>
          Second Town
        </Menu.Item>
        <Menu.Item className="sub-menu-item" onClick={() => handleNavigate(3)}>
          Third Town
        </Menu.Item>
        <Menu.Item className="sub-menu-item" onClick={() => handleNavigate(4)}>
          Fourth Town
        </Menu.Item> */}
      </SubMenu>
      <SubMenu title={<div onClick={()=>navigate("/possession-forms")}>Possesion Forms</div>}></SubMenu>
      <SubMenu title={<div onClick={()=>navigate("/sample-forms")} >Samples</div>}></SubMenu>
    </Menu>
  );
};

export default MenuDownloads;
