import React from "react";
import { Space } from "antd";
import { CopyrightOutlined } from "@ant-design/icons";
import "./styles.scss"

const FooterLower=()=>{
    return(
        <section className="lower-footer-section">
           <div>
            <Space>
            <CopyrightOutlined />
            <div>2020 Hussain Developers. All Rights Reserved</div>
            </Space>
           </div>
        </section>
    )
}

export default FooterLower;