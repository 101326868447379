import React from "react";
import Facilities from "./Education";
import "./styles.scss"

const AllFacilities=()=>{
    return(
        <section className="facilities-container">
            <div className="facilities-title">
                All Facilities 
            </div>
            <Facilities />
            
            
        </section>
    )
}

export default AllFacilities;