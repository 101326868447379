import {React,useEffect} from "react";
import { useParams } from "react-router-dom";
import { Image } from "antd";
import { TownMapsAll } from "../../HelperFunctions/homeBanner";
import "./styles.scss"

const TownAllMaps = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const params = useParams();
  const { townId } = params;
  return (
    <section className="town-map-container">
      {TownMapsAll.map((e) => {
        if (e?.id === townId) {
          return (
            <>
              <div className="town-title">{e?.title}</div>
              <div className="town-city">
                <img src={e?.icon} alt="" className="pin-icon-img" />
                <span className="town-city-text">
                  {e?.town},{e?.city}
                </span>
              </div>
              <div className="town-address">{e?.desc}</div>
              <a href={e?.img} target="_blank" download={e?.img}>
              <img src={e?.img} alt="#" className="town-img" />
              </a>
              
            </>
          );
        }
      })}
    </section>
  );
};

export default TownAllMaps;
