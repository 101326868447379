import React from "react";
import PhilosphyImg from "../../assets/VIEW 6.jpg";
import "./styles.scss";

const PhilosphyAll = () => {
  return (
    <section className="philosphy-container">
      
      <img src={PhilosphyImg} alt="#" className="philosphy-img" />
      <div className="philosphy-title">Our Philosophy</div>
      <p className="philosphy-text">
        Hussain Developer's philosophy is to view each project in its entirety,
        beyond mere physical planning, to include the optimization of social and
        economic needs, while treating both small and mega projects with equal
        emphasis to detail and quality.
      </p>
      {/* <p className="philosphy-text">
        Our world-class projects in Islamabad, Rawalpindi, Lahore and Karachi
        epitomize the highest standards of development ever achieved in
        Pakistan. Street layouts inspired from foreign designs, carpeted roads,
        tiled footpaths, street lights, scenic gardens, green areas, latest
        metropolitan facilities and standards create a picturesque landscape in
        every community. Hussain Developer's developments reflect and complement
        the exclusive lifestyle of its residents.
      </p> */}
    </section>
  );
};

export default PhilosphyAll;
