import {React,useEffect} from "react";
import ContatcUs from "../../components/ContactUs"

const Contacts=()=>{

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return <ContatcUs/>
}

export default Contacts;