import {React,useEffect} from "react";
import { Routes, Route } from "react-router-dom";
import HeaderAll from "./containers/HeaderMain";
import Footer from "./containers/Footer";
import AboutUs from "./containers/About";
import Contacts from "./containers/Contacts";
import TownAllMaps from "./components/TownMap";
import MapsAll from "./components/MapsAll";
import ProjectsAll from "./containers/Projects";
import ProjectDetail from "./components/ProjectAll/ProjectDetail";
import MediaCenterAll from "./containers/MediaCenter";
import PressDetail from "./components/MediaCenter/PressReleaseDetail";
import VideoSection from "./containers/YoutubeLinks";
import FacilitiesAll from "./containers/FacilitiesAll";
import PossessionForms from "./components/PossessionFormsAll";
import SampleForms from "./components/SampleForms";
import "./App.css";

const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderAll />
      <Routes>
        <Route exact path={"/"} element={<AboutUs />} />
        <Route exact path={"/downloads/maps"} element={<MapsAll />} />
        <Route
          exact
          path={"/downloads/maps/:townId"}
          element={<TownAllMaps />}
        />
        <Route exact path={"/possession-forms"} element={<PossessionForms />}/>
        <Route exact path={"/sample-forms"} element={<SampleForms />}/>
        <Route exact path={"/projects"} element={<ProjectsAll />}/>
        <Route exact path={"/press-release"} element={<MediaCenterAll />}/>
        <Route exact path={"/video-gallery"} element={<VideoSection />}/>
        <Route exact path={"/facilities"} element={<FacilitiesAll />}/>
        <Route
          exact
          path={"/press-release/:pressId"}
          element={<PressDetail />}
        />
        <Route exact path={"/projects/:projectId"} element={<ProjectDetail />}/>
        {/* <Route exact path={"/life-style"} element={<AboutUs />} />
         />
        <Route exact path={"/media-center"} element={<ServiceAll />} /> */}
        <Route exact path={"/contacts"} element={<Contacts />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
