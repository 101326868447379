import React from "react";
import excImg from "../../assets/WhatsApp Image 2024-03-09 at 5.23.29 PM (2).jpeg";
import { ExclusiveList } from "../../HelperFunctions/homeBanner";
import "./styles.scss";
const ExclusivityAll = () => {
  return (
    <section className="exc-all-section">
      <div className="exc-title">Exclusivity</div>
      <section className="exc-container">
        <section className="left-container">
          <img src={excImg} alt="#" className="exc-img" />
        </section>
        <section className="right-container">
          {ExclusiveList.map((e) => {
            return (
              <ul>
                <li className="exc-list">{e?.desc}</li>
              </ul>
            );
          })}
        </section>
      </section>
    </section>
  );
};

export default ExclusivityAll;
