import React from "react";
import { Card, Space } from "antd";
import FounderImg from "../../assets/ceo.jpeg";
import FacebookImg from "../../assets/facebook.png";
import TwitterImg from "../../assets/twitter.png";
import InstaImg from "../../assets/instagram.png";

import "./styles.scss";

const FounderMessage = () => {
  return (
    <section className="founder-img-mesg-section">
      <img src={FounderImg} alt="#" className="founder-img" />
      <Card className="founder-card">
        <div className="founder-title">Founder & Chairman</div>
        {/* <div className="founder-line">
          A Man Larger Than Life & A Legend For All Times to Come Mr. Hussain
          The Force & Vision Behind Hussain Developer.
        </div> */}
        <div className="founder-intro">
          I am delighted to craft exceptional residential projects that offer
          unparalleled lifestyle opportunities. Hussain Developers represents
          innovation, reliability, and quality in real estate development. Our
          comprehensive solutions cater to investors, businesses, residents and
          dealers, seamlessly blending residential, retail, and commercial
          realms. With pride in our virtuosity and unmatched services, we
          eagerly anticipate crafting extraordinary lifestyle opportunities for
          our esteemed patrons.
        </div>
        <div className="socialmedia-logos-container">
          <Space direction="horizontal" size={20} align="center">
            <img src={FacebookImg} alt="" className="s-icon" />
            <img src={TwitterImg} alt="" className="s-icon" />
            <img src={InstaImg} alt="" className="s-icon" />
          </Space>
        </div>
      </Card>
    </section>
  );
};

export default FounderMessage;
