import {React,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { TownMapsAll } from "../../HelperFunctions/homeBanner";
import { Card, Row, Col, Button } from "antd";
import "./styles.scss";

const MapsAll = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <section className="maps-all-container">
      <div className="title">Maps Of All Towns</div>
      <Row gutter={[24, 24]}>
        {TownMapsAll.map((e) => {
          return (
            <>
              <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                <Card className="town-map-card">
                  <div className="image-container">
                    <img src={e?.img} alt="#" className="town-map-img" />
                  </div>
                  <div className="town-card-title">{e?.title}</div>
                  <Button
                    type="primary"
                    block
                    onClick={() => navigate(`/downloads/maps/${e?.id}`)}
                  >
                    View Detail
                  </Button>
                </Card>
              </Col>
            </>
          );
        })}
      </Row>
    </section>
  );
};

export default MapsAll;
